import Vue from "vue";
import SignInOrUpForm from "./SignInOrUpForm.vue";
export default Vue.extend({
    components: {
        SignInOrUpForm: SignInOrUpForm,
    },
    data: function () {
        return {
            isSignUpVisible: false,
        };
    },
    methods: {
        onTabChange: function (isSignUpVisible) {
            this.isSignUpVisible = isSignUpVisible;
        },
        onSignInClick: function () {
            if (this.$refs.signInOrUpForm) {
                this.$refs.signInOrUpForm.submit();
            }
        },
        onSignUpClick: function () {
            if (this.$refs.signInOrUpForm) {
                this.$refs.signInOrUpForm.submit();
            }
        },
    },
});
