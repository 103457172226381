var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { mapState } from "vuex";
import addSavedProperty from "../../../api/mutations/addSavedProperty";
import SignInOrUpDialog from "../../auth/SignInOrUpDialog.vue";
import SavedPropertiesDialog from "./SavedPropertiesDialog.vue";
export default Vue.extend({
    components: {
        SignInOrUpDialog: SignInOrUpDialog,
        SavedPropertiesDialog: SavedPropertiesDialog,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
        className: {
            type: String,
            default: undefined,
        },
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment SaveButton_property on PropertyListing {\n        id\n        isLeased\n        isStudentSaved\n      }\n    "], ["\n      fragment SaveButton_property on PropertyListing {\n        id\n        isLeased\n        isStudentSaved\n      }\n    "]))),
    },
    data: function () {
        return {
            showSignIn: false,
            showSavedProperties: false,
        };
    },
    computed: mapState(["authUser"]),
    methods: {
        performAddSaved: function () {
            var _this = this;
            this.$apollo.mutate(addSavedProperty(this.property.id)).then(function () {
                _this.showSavedProperties = true;
            });
        },
        onCloseSavedProperties: function () {
            this.showSavedProperties = false;
        },
        onSave: function () {
            if (this.authUser && this.authUser.type === "student") {
                this.performAddSaved();
                return;
            }
            this.showSignIn = true;
        },
        onSignedIn: function () {
            this.onCloseSignIn();
            this.performAddSaved();
        },
        onCloseSignIn: function () {
            this.showSignIn = false;
        },
    },
});
var templateObject_1;
