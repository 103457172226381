import "urijs/src/URITemplate";
import * as Uri from "urijs";
var BASE_URL = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/";
export var mapboxStaticMapUrl = function (accessToken, latLong, _a) {
    var showPin = _a.showPin, width = _a.width, height = _a.height;
    return BASE_URL +
        (showPin
            ? Uri.expand("pin-s+ff0000({long},{lat})/", latLong).toString()
            : "") +
        Uri.expand("{long},{lat},{zoom},0,0/{width}x{height}", {
            lat: latLong.lat,
            long: latLong.long,
            zoom: 13,
            width: width,
            height: height,
        })
            .addQuery("access_token", accessToken)
            .toString();
};
export var toMapboxLatLong = function (_a) {
    var long = _a.long, lat = _a.lat;
    return [
        long,
        lat,
    ];
};
