var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { toMapboxLatLong } from "../../../../utils/mapbox";
import institutionDisplayNameFragment from "../../../../utils/institutionDisplayNameFragment.graphql";
export default Vue.extend({
    props: {
        institution: {
            type: Object,
            required: true,
        },
        isHighlighted: {
            type: Boolean,
            default: false,
        },
    },
    fragments: {
        institution: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment InstitutionMarker_institution on Institution {\n        ...InstitutionDisplayName_institution\n        latLong {\n          lat\n          long\n        }\n      }\n      ", "\n    "], ["\n      fragment InstitutionMarker_institution on Institution {\n        ...InstitutionDisplayName_institution\n        latLong {\n          lat\n          long\n        }\n      }\n      ", "\n    "])), institutionDisplayNameFragment),
    },
    computed: {
        mapboxLatLong: function () {
            return toMapboxLatLong(this.institution.latLong);
        },
    },
});
var templateObject_1;
