var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
export default (function (id) { return ({
    mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation addSavedPropertyMutation($input: AddSavedPropertyInput!) {\n      addSavedProperty(input: $input) {\n        propertyListing {\n          id\n          isStudentSaved\n        }\n      }\n    }\n  "], ["\n    mutation addSavedPropertyMutation($input: AddSavedPropertyInput!) {\n      addSavedProperty(input: $input) {\n        propertyListing {\n          id\n          isStudentSaved\n        }\n      }\n    }\n  "]))),
    variables: { input: { id: id } },
}); });
var templateObject_1;
